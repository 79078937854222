import apiClient from "../constants/apiClient";
import emptyAuctionCategoryPage from "./emptySubCategoryAuction";
import emptyLot from "./emptyLot";
import arraySum from "./arraySum";
import { message } from "antd";
import { sendRequest } from "../store/auth";
import { parseResponse } from "./parseResponse";

export function swrFetcher(url, token) {
  return apiClient
    .get(url, {
      headers: {
        Authorization: `Bearer ${token.replace(/"/g, "")}`,
      },
    })
    .then((res) => {
      const isDataMetaObject =
        res?.data?.hasOwnProperty?.("data") &&
        res?.data?.hasOwnProperty?.("meta");

      if (isDataMetaObject) return parseResponse(res.data);

      return res.data;
    });
}

export function noTokenFetcher(url) {
  return apiClient.get(url).then((res) => {
    const isDataMetaObject = !!res?.data?.data && !!res?.data?.meta;
    if (isDataMetaObject) return parseResponse(res);

    return res.data;
  });
}

export function auctionSwrFetcher(url, token, user) {
  return apiClient
    .get(url, {
      headers: {
        Authorization: `Bearer ${token.replace(/"/g, "")}`,
      },
    })
    .then((res) => {
      const auction = res.data;
      if (
        (auction?.visibility === "Public" &&
          auction?.ndaTemplate?.nda_document === null) ||
        auction?.participants?.includes(user?.id) ||
        auction?.clients?.includes(user?.id) ||
        auction?.authors?.includes(user?.id)
      ) {
        return auction;
      }
      auction.participants = [];
      auction.invited = [];
      auction.biddingSteps = [];
      auction.insolvency = {
        id: auction.insolvency.id,
        name: null,
        description: null,
        images: null,
        customFolderTitle: null,
        documents: null,
        financialDocuments: null,
        generalDocuments: null,
        currentBid: null,
        startingBid: "0",
      };
      auction.lots = [
        {
          id: null,
          name: null,
          subCategory: {
            category: {
              title: "",
            },
          },
          description: null,
          images: null,
          documents: null,
          financialDocuments: null,
          generalDocuments: null,
          currentBid: null,
          startingBid: "0",
        },
      ];
      return auction;
    });
}

export function lotSwrFetcher(url, token, auction, user) {
  if (
    (auction?.visibility === "Public" &&
      auction?.ndaTemplate?.nda_document == null) ||
    auction?.participants?.includes(user?.id) ||
    auction?.clients?.includes(user?.id) ||
    auction?.authors?.includes(user?.id)
  ) {
    return apiClient
      .get(url, {
        headers: {
          Authorization: `Bearer ${token.replace(/"/g, "")}`,
        },
      })
      .then((res) => {
        const isDataMetaObject = !!res?.data?.data && !!res?.data?.meta;
        if (isDataMetaObject) return parseResponse(res);

        return res.data;
      });
  }

  return emptyLot;
}

export function categoriesFetcher(url, token) {
  return apiClient
    .get(url, {
      headers: {
        Authorization: `Bearer ${token.replace(/"/g, "")}`,
      },
    })
    .then((res) => {
      const categories = {};

      let data = res?.data;

      const isDataMetaObject =
        Object.keys(data).length === 2 && "data" in data && "meta" in data;

      if (isDataMetaObject) data = parseResponse(data);

      data.forEach((subCat) => {
        if (subCat.category.title in categories) {
          categories[subCat.category.title].push({
            name: subCat.title,
            id: subCat.id,
          });
        } else {
          categories[subCat.category.title] = [
            { name: subCat.title, id: subCat.id },
          ];
        }
      });

      return categories;
    });
}

export function currentBidFetcher(
  url,
  userToken,
  auctionDataPresent,
  lastTwoBids = false
) {
  if (auctionDataPresent) {
    return apiClient
      .get(url, {
        headers: {
          Authorization: `Bearer ${userToken.replace(/"/g, "")}`,
        },
      })
      .then((res) => {
        if (lastTwoBids) {
          return res.data;
        } else {
          const currentBid = res.data[0];
          const noBid = res.status;
          if (currentBid !== undefined) {
            return currentBid;
          } else {
            return noBid;
          }
        }
      });
  }

  return null;
}

export function userBidFetcher(url, token, auctionDataPresent) {
  if (auctionDataPresent) {
    return apiClient
      .get(url, {
        headers: {
          Authorization: `Bearer ${token.replace(/"/g, "")}`,
        },
      })
      .then((res) =>
        res.data.map((bid) => {
          // Set the endDate of the auction equal to the endDate of the current step.
          const currentStep = bid?.auction?.auctionSteps?.find(
            (step) => step?.step === bid?.auction?.status
          );

          if (
            currentStep?.step === "Dataroom" ||
            currentStep?.step === "Visibility" ||
            currentStep?.step === "Individual"
          ) {
            return {
              ...bid,
              auction: { ...bid.auction, endDate: currentStep.endDate },
            };
          }

          return bid;
        })
      );
  }

  return null;
}

export function subCategoryPageFetcher(
  url,
  token,
  user,
  categoryId,
  subCategoryId,
  userBids
) {
  return apiClient
    .get(url, {
      headers: {
        Authorization: `Bearer ${token.replace(/"/g, "")}`,
      },
    })
    .then((res) => {
      const auction = res.data || {};
      if (
        (auction?.visibility === "Public" &&
          auction?.ndaTemplate?.nda_document == null) ||
        auction?.participants?.includes(user?.id) ||
        auction?.clients?.includes(user?.id) ||
        auction?.authors?.includes(user?.id)
      ) {
        let subCategoryLots = [];
        let combinedSubCategoryLot = null;

        // Get all subCategory lots and populate the yourBid property.
        subCategoryLots = auction.lots.filter(
          (lot) =>
            !lot.isCategory &&
            lot?.subCategory?.id === parseInt(subCategoryId, 10)
        );
        subCategoryLots = subCategoryLots.map((lot) => ({
          ...lot,
          yourBid:
            userBids?.find((bid) => bid.lot.id === lot.id)?.amount || null,
        }));

        // Set properties about category and subCategory.
        auction.category = parseInt(categoryId, 10);
        auction.categoryTitle =
          subCategoryLots?.[0]?.subCategory?.category?.title;
        auction.subCategory = parseInt(subCategoryId, 10);
        auction.subCategoryTitle = subCategoryLots?.[0]?.subCategory?.title;

        // Find the combined subCategory lot (lot consisting of all subCategory lots). One can bid on
        // this lot in the combined phase.
        combinedSubCategoryLot =
          auction.lots.find(
            (lot) =>
              lot?.isCategory &&
              lot?.subCategory?.id === parseInt(subCategoryId, 10)
          ) || null;

        // Get the endDate for the current phase.
        auction.endDate =
          auction?.auctionSteps?.find((step) => step?.step === auction?.status)
            ?.endDate || auction.endDate;

        // Calculate the starting bid for the combined subCategoryLot (sum of all highest bids on
        // the subCategory lots).
        if (combinedSubCategoryLot && auction.status === "Combined") {
          const sumOfBids = arraySum(
            subCategoryLots.map((lot) => Number(lot.currentBid))
          );
          combinedSubCategoryLot.startingBid = Math.max(
            Number(combinedSubCategoryLot.startingBid),
            sumOfBids
          ).toString();
        }

        return { auction, subCategoryLots, combinedSubCategoryLot };
      }

      return {
        auction: emptyAuctionCategoryPage,
        subCategoryLots: [],
        combinedSubCategoryLot: null,
      };
    });
}

// Check if subCategory is already added.
function subCategoryAlreadyAdded(subCatTitle, subCatArray) {
  return subCatArray.some((subCat) => subCatTitle === subCat.title);
}

export function categoryPageFetcher(url, token, user, categoryId, userBids) {
  return apiClient
    .get(url, {
      headers: {
        Authorization: `Bearer ${token.replace(/"/g, "")}`,
      },
    })
    .then((res) => {
      const auction = res.data || {};
      if (
        (auction?.visibility === "Public" &&
          auction?.ndaTemplate?.nda_document == null) ||
        auction?.participants?.includes(user?.id) ||
        auction?.clients?.includes(user?.id) ||
        auction?.authors?.includes(user?.id)
      ) {
        let combinedCategoryLot = null;

        // Get all lots belonging to the category and populate them with yourBid.
        let categoryLots = auction.lots.filter(
          (lot) =>
            !lot.isCategory &&
            lot?.subCategory?.category?.id === parseInt(categoryId, 10)
        );
        categoryLots = categoryLots.map((lot) => ({
          ...lot,
          yourBid:
            userBids?.find((bid) => bid.lot.id === lot.id)?.amount || null,
        }));

        // Fill the array with subCategories.
        const subCatArray = [];
        categoryLots.forEach((lot) => {
          // eslint-disable-next-line max-len
          if (
            lot?.subCategory?.title &&
            !subCategoryAlreadyAdded(lot.subCategory.title, subCatArray)
          ) {
            subCatArray.push({
              title: lot.subCategory.title,
              id: lot.subCategory.id,
            });
          }
        });

        // Set properties of the category and subCategories array.
        auction.subCategories = subCatArray;
        auction.category = parseInt(categoryId, 10);
        auction.categoryTitle = categoryLots?.[0]?.subCategory?.category?.title;

        // Find the combined category lot (lot consisting of all category and subCategory lots).
        // One can bid on this lot in the combined phase.
        combinedCategoryLot =
          auction.lots.find(
            (lot) =>
              lot?.isCategory &&
              lot?.subCategory?.id === parseInt(categoryId, 10)
          ) || null;

        // Get the endDate for the current phase.
        auction.endDate =
          auction?.auctionSteps?.find((step) => step?.step === auction?.status)
            ?.endDate || auction.endDate;

        // Calculate the starting bid for the combined subCategoryLot (sum of all highest bids on
        // the subCategory lots).
        if (combinedCategoryLot && auction.status === "Combined") {
          const sumOfBids = arraySum(
            categoryLots.map((lot) => Number(lot.currentBid))
          );
          combinedCategoryLot.startingBid = Math.max(
            Number(combinedCategoryLot.startingBid),
            sumOfBids
          ).toString();
        }

        const groupedCategoryLots = categoryLots?.reduce((acc, lot) => {
          const subCategory = lot?.subCategory?.title;
          if (!acc[subCategory]) {
            acc[subCategory] = [];
          }
          acc[subCategory].push(lot);
          return acc;
        }, {});

        return {
          auction,
          categoryLots,
          combinedCategoryLot,
          groupedCategoryLots,
        };
      }

      return {
        auction: emptyAuctionCategoryPage,
        categoryLots: [],
        combinedCategoryLot: null,
      };
    });
}

export const setMessageAsOpened = async (id) => {
  try {
    await sendRequest("put", `/messages/${id}`, { isOpened: true });
  } catch (e) {
    message.error("Error: ", e);
  }
};

export const setMessageAsShown = async (id) => {
  try {
    await sendRequest("put", `/messages/${id}`, { hasShown: true });
  } catch (e) {
    message.error("Error: ", e);
  }
};

export const messageFetcher = (url, userToken) =>
  apiClient
    .get(url, {
      headers: {
        Authorization: `Bearer ${userToken.replace(/"/g, "")}`,
      },
    })
    .then((res) => res.data);
